@use '../layout/constants/_fonts.scss';

*,
*::after,
*::before {
  font-family: 'SourceSansProRegular', sans-serif;
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  margin: 0;
  padding: 0;
}

.main-content {
  padding: 0;
  margin-top: 0;
}