@use "../../layout/constants/_colors" as color;
@use "../../layout/constants/_breakpoints" as bp;

.signCard {
  background-color: color.$white;
  max-width: 400px;
  width: 100%;
  border: 1px solid color.$primary-card;

  &__titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: color.$white;
    height: 80px;
    gap: 4px;

    &[bgcolor="gray"] {
      background-color: color.$primary-card;
    }
    &[bgcolor="lightBlue"] {
      background-color: color.$secondary-card;
    }
    &[bgcolor="darkBlue"] {
      background-color: color.$tertiary-card;
    }
    &[bgcolor="purple"] {
      background-color: color.$quaternary-card;
    }

    &__title {
      font-size: 30px;
      font-weight: bold;
    }
  }

  &__contentBox {
    background-color: color.$white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    color: color.$dark-gray;
    padding: 20px 80px;
    font-weight: lighter;
    text-align: center;

    & > h1 {
      color: color.$primary-card;
      font-size: 40px;
      font-weight: bold;
    }

    & > p {
      font-size: 18px;
    }
    & > span {
      font-size: 14px;
    }
  }

  &__contentPriceBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    color: color.$dark-gray;
    padding: 20px 20px;
    font-weight: lighter;
    text-align: center;

    &__priceRow {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid color.$primary-card;
      padding-bottom: 20px;

      &__userLabel {
        display: flex;
        flex-direction: column;

        &__text1 {
          font-size: 34px;
          font-weight: bold;
          @include bp.sm-max {
            font-size: 24px;
          }
        }
        &__text2 {
          font-size: 20px;
          font-weight: 500;
          @include bp.sm-max {
            font-size: 15px;
          }
        }
      }
    }

    & > h1 {
      &[bgcolor="lightBlue"] {
        color: color.$secondary-card;
      }
      &[bgcolor="darkBlue"] {
        color: color.$tertiary-card;
      }
      &[bgcolor="purple"] {
        color: color.$quaternary-card;
      }
      font-size: 40px;
      font-weight: bold;
    }

    & > p {
      font-size: 18px;
      border-bottom: 1px solid color.$primary-card;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    & > span {
      font-size: 14px;
    }
  }
}
