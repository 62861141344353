@use "../../layout/constants/_colors" as color;
@use "../../layout/constants/_breakpoints" as bp;
@use "../../layout/constants/_fonts";

.apps {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  &__img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    align-items: center;

    &__icon {
      max-width: 80px;

      width: 100%;
    }

    &__shadow {
      max-width: 120px;
      width: 100%;
    }
  }

  & > label {
    color: color.$primary-title;
    font-family: "SourceSansBold", sans-serif;
    font-size: 20px;
    @include bp.md-max {
      font-size: 18px;
    }
    &[ready="false"] {
      color: color.$secondary-title;
    }
  }
}
