@use "../../layout/constants/_colors" as color;
@use "../../layout/constants/_breakpoints" as bp;

.title {
  font-family: "SourceSansBold";
  text-align: center;
  font-weight: bold;
  padding: 90px 0 48px 0;

  &[ready="true"] {
    color: color.$primary-title;
    font-size: 40px;
  }

  &[ready="false"] {
    color: color.$secondary-title;
    padding-top: 70px;
    font-size: 28px;
  }
}

.subtitle {
  font-family: "SourceSansSemiBold";
  text-align: center;
  font-weight: bold;
  margin-top: -55px;
  margin-bottom: 30px;
  color: color.$primary-title;
  font-size: 22px;
}
