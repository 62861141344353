@use "../../layout/constants/_colors" as color;
@use "../../layout/constants/_breakpoints" as bp;

.hero {
  background-image: url("../../assets/img-sistema/medico.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 640px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &[prelaunch="true"] {
    padding-top: 12%;
  }

  &__logo {
    min-width: 100px;
    width: 10%;
    color: color.$white;
    margin-bottom: 20px;
  }

  &__title {
    font-weight: bold;
    color: color.$white;
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
  }

  &__subtitle {
    width: 250px;
    color: color.$white;
    text-align: center;
    font-size: 15px;
    margin-bottom: 35px;
  }

  &__next {
    color: color.$white;
    font-size: 12px;
    text-decoration: none;
    margin-top: 15px;
    font-weight: 600;
  }

  &__discountBox {
    cursor: pointer;
    background-color: color.$pre-launch-blue;
    margin-top: 40px;
    width: 40%;
    height: fit-content;
    padding: 30px 40px;
    font-family: "SourceSansBold";
    font-size: 32px;
    color: color.$primary-title;
    text-align: center;

    @include bp.sm-max {
      width: 80%;
    }
  }
  & > div:hover {
    background-color: color.$success-text;
  }
}
