@use "../../layout/constants/_colors" as color;
@use "../../layout/constants/_breakpoints" as bp;

.navbar {
  position: fixed;
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  font-size: 20px;
  font-weight: 600;

  @include bp.md-max {
    gap: 20px;
  }

  & > a {
    text-decoration: none;
    color: color.$secondary-card;

    @include bp.sm-max {
      display: none;
    }
  }

  &__login {
    background-image: url("../../assets/img-sistema/svg/icon-login.svg");
    background-repeat: no-repeat;
    position: relative;
    left: -90px;
    cursor: pointer;

    @include bp.md-max {
      left: -10px;
    }

    @include bp.sm-max {
      left: 0;
    }

    & > a {
      text-decoration: none;
      margin-left: 28px;
      color: color.$white;
    }
  }
}

.navbarScroll {
  background: linear-gradient(
    to bottom,
    #354f5e 0%,
    #354f5e 50%,
    rgba(53, 79, 94, 0) 100%
  );
  transition: background 0.3s;
}
