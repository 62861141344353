@use "../constants/_colors" as color;
@use "../constants/_breakpoints" as bp;

@mixin disp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
}

.prelaunch {
  &__app {
    margin-top: 60px;
    margin-bottom: 60px;
    min-height: 640px;

    @include bp.sm-max {
      height: fit-content;
    }

    &__dev {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 5%;
      row-gap: 25px;
      white-space: nowrap;
      margin-left: 10px;

      @include bp.sm-max {
        flex-direction: column;
      }
    }
    &__animations {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 5%;
      row-gap: 25px;
      white-space: nowrap;
      margin-left: 10px;
      margin-top: 70px;
      & > div {
        text-align: center;
        & > h3 {
          color: color.$dark-gray;
        }
      }

      @include bp.sm-max {
        flex-direction: column;
      }
    }
  }

  &__preregistration {
    @include disp;
    background-color: color.$dark-blue;
    padding: 40px;

    & > img {
      max-width: 40px;
    }

    & h1,
    h2 {
      color: color.$white;
      font-family: "SourceSansSemiBold", sans-serif;
      text-align: center;
    }

    & > h1 {
      font-size: 48px;
      margin-bottom: -15px;
    }
    & > h2 > span {
      color: color.$pre-launch-blue;
      font-family: "SourceSansSemiBold", sans-serif;
    }

    & > h3 {
      font-family: "SourceSansSemiBold", sans-serif;
      color: color.$success-text;
      font-size: 24px;
    }

    & > p {
      text-align: center;
      max-width: 600px;
      font-size: 20px;
      color: color.$white;
    }

    & > form {
      width: 100%;
      @include disp;

      & > input {
        max-width: 400px;
        width: 100%;
        height: 40px;
        background-color: color.$input-bg;
        border: none;
        outline: none;
        padding: 10px;
      }

      & > input:required:focus:invalid {
        border: 3px color.$input-error solid;
      }

      & > input:hover {
        background-color: color.$input-hover;
      }

      & > input::-webkit-outer-spin-button,
      & > input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      & > input[type="number"] {
        appearance: textfield;
      }
    }
  }
}
