@font-face {
  font-family: "SourceSansProRegular";
  src: url("../../assets/fonts/SOURCESANSPRO-REGULAR.ttf");
}

@font-face {
  font-family: "SourceSansBold";
  src: url("../../assets/fonts/SOURCESANSPRO-BOLD.ttf");
}

@font-face {
  font-family: "SourceSansSemiBold";
  src: url("../../assets/fonts/SOURCESANSPRO-SEMIBOLD.ttf");
}
