$primary-title: #2c3f4e;
$secondary-title: #ababab;

$primary-navbar: #37c3c4;
$primary-footer: #304352;

$primary-card: #858585;
$secondary-card: #30bbbe;
$tertiary-card: #3e6b8c;
$quaternary-card: #4b4a83;

$pre-launch-blue: #37dcde;

// Standard Colors
$white: #fff;
$black: #000;
$dark-gray: #303030;
$light-gray: #c7c7c7;
$dark-blue: #304352;
$input-bg: #9db4b4;
$input-hover: #cde6e6;
$input-error: #ff738d;
$success-text: #d5ff6e;
