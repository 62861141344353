@use "../../layout/constants/_colors" as color;
@use "../../layout/constants/_breakpoints" as bp;

.button {
  cursor: pointer;

  &[hero="true"] {
    max-width: 235px;
    width: 100%;
    height: 43px;
    color: color.$primary-title;
    font-size: 20px;
    font-weight: bold;
    border: none;
  }

  &[hero="false"] {
    max-width: 120px;
    width: 100%;
    height: 58px;
    color: color.$white;
    font-size: 20px;
    border: none;
  }

  &[prereg="true"] {
    max-width: 200px;
    width: 100%;
    height: 58px;
    color: color.$primary-title;
    font-size: 24px;
    font-family: "SourceSansBold", sans-serif;
    border: none;
  }

  &[btcolor="gray"] {
    background-color: color.$primary-card;
  }
  &[btcolor="lightBlue"] {
    background-color: color.$secondary-card;
  }
  &[btcolor="prelaunch"] {
    background-color: color.$pre-launch-blue;
    &:hover {
      background-color: color.$success-text;
    }
    &:focus {
      background-color: color.$success-text;
    }
  }
  &[btcolor="darkBlue"] {
    background-color: color.$tertiary-card;
  }
  &[btcolor="purple"] {
    background-color: color.$quaternary-card;
  }
}
