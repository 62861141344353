@mixin xs {
    @media (max-width: 599px) {
      @content;
    }
  }
  @mixin sm {
    @media (min-width: 600px) {
      @content;
    }
  }
  @mixin sm-max {
    @media (max-width: 600px) {
      @content;
    }
  }
  @mixin md {
    @media (min-width: 900px) {
      @content;
    }
  }
  @mixin md-max {
    @media (max-width: 900px) {
      @content;
    }
  }
  @mixin lg {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @mixin lg-max {
    @media (max-width: 1200px) {
      @content;
    }
  }
  @mixin lg-xl {
    @media (max-width: 1500px) {
      @content;
    }
  }
  @mixin md-lg {
    @media (max-width: 1088px) {
      @content;
    }
  }
  @mixin xl {
    @media (min-width: 1800px) {
      @content;
    }
  }