@use "../constants/_colors" as color;
@use "../constants/_breakpoints" as bp;

.home {
  &__app {
    margin-bottom: 60px;
    height: 640px;

    @include bp.sm-max {
      height: fit-content;
    }

    &__prod {
      display: flex;
      justify-content: center;
      gap: 10%;
    }

    &__dev {
      display: flex;
      justify-content: center;
      gap: 10%;
      white-space: nowrap;
      margin-left: 10px;

      @include bp.md-max {
        gap: 2%;
      }
      @include bp.sm-max {
        gap: 18px;
        flex-direction: column;
      }
    }
  }

  &__video {
    background-image: url("../../assets/img-sistema/video.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
  }

  &__sign {
    background-color: color.$light-gray;
    padding-bottom: 60px;

    &__apps {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin: 0 30px;

      @include bp.lg-xl {
        flex-wrap: wrap;
      }
    }
  }

  &__screens {
    padding-bottom: 80px;

    &__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;

      &__column {
        flex: 30%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 30px;
        max-width: 30%;

        & > img {
          width: 100%;
        }
      }
    }
  }

  &__footer {
    background-color: color.$primary-footer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;
    @include bp.sm-max {
      flex-direction: column;
      padding: 30px;
    }

    &__logo {
      width: 8%;
      color: color.$white;
      margin-right: 60px;
      @include bp.sm-max {
        width: 20%;
        margin: 0 auto;
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-right: 60px;
      @include bp.sm-max {
        margin: 20px auto;
      }

      & > a {
        text-decoration: none;
        color: color.$secondary-card;
        font-weight: 600;
      }
    }

    &__media {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      @include bp.sm-max {
        margin-left: 40px;
      }

      &__row {
        display: flex;
        color: color.$white;
        justify-content: flex-start;
        gap: 5px;
        cursor: pointer;

        & > img {
          width: 15px;
        }
        & > span {
          font-weight: 600;
        }
      }
    }
  }
}
